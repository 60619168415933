import { createEffect } from 'effector'
import { createBrowserHistory, createMemoryHistory } from 'history'

export let history = createBrowserHistory()
/*
 * TODO: убрать обработку тестового окружения из этого файла
 * */
if (process.env.NODE_ENV === 'test') {
  history = createMemoryHistory()
}

export const pushFx = createEffect<string, void>({
  async handler(to) {
    history.push(to)
  },
})

export const replaceFx = createEffect<string, void>({
  async handler(to) {
    history.replace(to)
  },
})

export const backFx = createEffect({
  async handler() {
    history.goBack()
  },
})
