import { IonRouterOutlet } from '@ionic/react'
import { ReactNode } from 'react'

type Props = {
  children?: ReactNode
}

export function MainLayout({ children }: Props) {
  return <IonRouterOutlet>{children || null}</IonRouterOutlet>
}
