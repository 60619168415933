import { LoadingButton } from '@mui/lab'
import { useStore } from 'effector-react'
import React, { useRef, useState } from 'react'
import SignaturePad from 'react-signature-canvas'
import { $carId } from '~/entities/Inspection/model'
import { Inspection, ThumbType } from '~/shared/api'
import { InspectionPhotoTypeEnum } from '~/shared/config/enums'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { myPalette } from '~/shared/ui/theme'
import { Wrapper } from './styled'

type SignatureProps = {
  onSuccess: (sign: ThumbType) => void
  isSubmitting: boolean
}

const imageType = 'image/jpeg'

export function Signature({ onSuccess, isSubmitting }: SignatureProps) {
  const padRef = useRef<SignaturePad>(null)
  const carId = useStore($carId) as string
  const [isSignSubmitting, toggleSignSubmitting] = useState<boolean>()

  const handleSuccess = () => {
    const isEmpty = padRef.current?.isEmpty()
    const canvas = padRef.current?.getCanvas()

    if (isEmpty) {
      snackbarEnqueued({
        message: 'Для того чтобы завершить осмотр поставьте подпись',
        variant: 'warning',
      })
      return
    }

    canvas?.toBlob(async (blob) => {
      try {
        toggleSignSubmitting(true)

        const sign = new File([blob as Blob], 'signature.jpg', {
          type: imageType,
        })
        const resThumb = await Inspection.savePhoto(
          sign,
          carId,
          InspectionPhotoTypeEnum.DRIVER_SIGNATURE,
        )
        onSuccess(resThumb)
      } finally {
        toggleSignSubmitting(false)
      }
    }, imageType)
  }

  return (
    <>
      <Wrapper>
        <SignaturePad
          clearOnResize={false}
          ref={padRef}
          backgroundColor={myPalette.white.main}
          canvasProps={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            'data-testid': 'signature-canvas',
          }}
        />
      </Wrapper>

      <LoadingButton
        fullWidth
        variant='limeFlooded'
        onClick={handleSuccess}
        loading={isSignSubmitting || isSubmitting}
      >
        Завершить осмотр
      </LoadingButton>
    </>
  )
}
