import { IonContent, IonFooter, IonHeader, IonModal } from '@ionic/react'
import { Button, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { ThumbType } from '~/shared/api'
import { ImageSlider } from '~/shared/ui/ImageSlider'
import { IonToolbarFooter, IonToolbarHeader } from '~/shared/ui/PageContainer'
import { WrapperFooter, WrapperHeader } from './styled'

type ImageSliderModalProps = {
  photos?: ThumbType[]
  isShow: boolean
  onCancel: () => void
  title: string
  empty?: string
}

export function ImageSliderModal({
  photos,
  isShow,
  onCancel,
  title,
  empty = 'Изображения не найдены',
}: ImageSliderModalProps) {
  const images = useMemo(() => {
    return (photos || []).map((photo) => ({
      id: photo.uuid,
      src: photo.originalUrlDownload,
    }))
  }, [photos])

  return (
    <IonModal
      isOpen={isShow}
      keepContentsMounted={false}
      onWillDismiss={onCancel}
    >
      <IonHeader>
        <IonToolbarHeader>
          <WrapperHeader>
            <Typography
              fontSize={20}
              fontWeight={600}
              color='aliceBlue.main'
              data-testid='image-slider-title'
            >
              {title}
            </Typography>
          </WrapperHeader>
        </IonToolbarHeader>
      </IonHeader>
      <IonContent
        style={{
          '--overscroll-behavior': 'none',
          '--webkit-overscroll-behavior': 'none',
          '--overflow': 'hidden',
        }}
      >
        {photos?.length ? (
          <ImageSlider images={images} />
        ) : (
          <Typography sx={{ margin: '16px', textAlign: 'center' }}>
            {empty}
          </Typography>
        )}
      </IonContent>
      <IonFooter>
        <IonToolbarFooter>
          <WrapperFooter>
            <Button
              variant='text'
              size='large'
              color={'primary'}
              onClick={onCancel}
            >
              Закрыть
            </Button>
          </WrapperFooter>
        </IonToolbarFooter>
      </IonFooter>
    </IonModal>
  )
}
