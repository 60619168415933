import { Box } from '@mui/material'
import { isPointInPolygon } from 'geolib'
import { omit } from 'lodash'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import ImageMarker, {
  Marker as MarkerType,
  MarkerComponentProps,
} from 'react-image-marker'
import { ImageParams, Polygon, PolygonMarker } from '../types'
import { Marker } from './ui/Marker'
import { Zoom } from './ui/Zoom'

type MarkersProps<T extends string> = {
  polygons?: Polygon<T>[]
  img: string
  onMarkerAdd?: (marker: PolygonMarker) => void
  onMarkerSelect?: (marker: PolygonMarker) => void
  markers: PolygonMarker[]
  dataTestId?: string
}

export function PolygonMarkers<T extends string>({
  polygons,
  img,
  onMarkerAdd,
  onMarkerSelect,
  markers,
  dataTestId,
}: MarkersProps<T>) {
  const [imageParams, setImageParams] = useState<ImageParams>({
    width: 0,
    height: 0,
  })
  const [markerScale, setMarkerScale] = useState<number>(1)

  const mappedPolygons = useMemo(
    () =>
      (polygons || []).map((polygon) => ({
        ...polygon,
        coordinates: polygon.coordinates.map(({ x, y }) => ({
          latitude: x,
          longitude: y,
        })),
      })),
    [polygons],
  )

  const handleAddMarker = (markerPosition: MarkerType) => {
    const point = {
      latitude: ((markerPosition.left as number) / 100) * imageParams.width,
      longitude: ((markerPosition.top as number) / 100) * imageParams.height,
    }
    const foundPolygon = mappedPolygons.find(({ coordinates }) =>
      isPointInPolygon(point, coordinates),
    )
    if (!foundPolygon) return

    onMarkerAdd?.({
      code: foundPolygon.code,
      top: parseFloat(markerPosition.top.toFixed(4)),
      left: parseFloat(markerPosition.left.toFixed(4)),
    })
  }

  const handleMarkerClick = useCallback(
    (data: PolygonMarker) => {
      onMarkerSelect?.(data)
    },
    [onMarkerSelect],
  )

  useEffect(() => {
    const imgObj = new Image()
    imgObj.src = img

    imgObj.onload = () => {
      setImageParams({
        width: imgObj.naturalWidth,
        height: imgObj.naturalHeight,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleZoomChange = (scale: number) => {
    setMarkerScale(scale)
  }

  const markerComponent = useCallback(
    (markerProps: MarkerComponentProps) => (
      <Box
        sx={{
          cursor: 'pointer',
        }}
        onClick={() =>
          handleMarkerClick(omit(markerProps, 'itemNumber') as PolygonMarker)
        }
      >
        <Marker {...markerProps} />
      </Box>
    ),
    [handleMarkerClick],
  )

  return (
    <Zoom onZoom={handleZoomChange}>
      <Box
        data-testid={dataTestId}
        sx={{
          '.image-marker__marker': {
            transform: `scale(${1 / markerScale})`,
            transition: 'transform 0.3s',
          },
        }}
      >
        <ImageMarker
          src={img}
          markers={markers}
          bufferLeft={0}
          bufferTop={0}
          markerComponent={markerComponent}
          onAddMarker={handleAddMarker}
        />
      </Box>
    </Zoom>
  )
}
