import { ToManyRelation } from 'coloquent'
import * as z from 'zod'

import { Car } from '~/shared/api/car'
import { ThumbType } from '~/shared/api/types'
import {
  DocumentsTypeEnum,
  IncidentResponsibleEnum,
  InspectionPhotoTypeEnum,
  InspectionServiceTypeEnum,
  WheelCodeEnum,
} from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel, ToOneRelation } from './core'
import { InspectionDocument } from './inspectionDocument'
import { InspectionEquipment } from './inspectionEquipment'
import { InspectionReason } from './inspectionReason'
import { InspectionWheelsState } from './inspectionWheelsState'

const attributesSchema = z.object({
  carId: z.string(),

  reasonId: z.string(),
  incidentResponsible: z.nativeEnum(IncidentResponsibleEnum).optional(),
  isExteriorClean: z.boolean().optional().default(true),
  isCarRefueled: z.boolean().optional(),
  withDriverParticipation: z.boolean().optional(),
  isPlanned: z.boolean().optional(),

  hasTobaccoSmell: z.boolean().optional(),
  isInteriorDirty: z.boolean().optional(),
  isDryCleaningNeed: z.boolean().optional(),

  mileage: z.number(),
  startedAt: z.string(),
  duration: z.number(),
  finishedAt: z.string(),

  comment: z.string().optional(),

  photos: z.array(
    z.object({
      originalUrl: z.string(),
      previewUrl: z.string(),
      originalUrlDownload: z.string(),
      previewUrlDownload: z.string(),
      type: z.nativeEnum(InspectionPhotoTypeEnum),
      uuid: z.string(),
    }),
  ),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)

export type InspectionAttributes = z.infer<typeof attributesSchema>

export type InspectionServiceApi = {
  type: InspectionServiceTypeEnum
  data: (string | number)[]
}
export type InspectionWheelApi = {
  code: WheelCodeEnum
  rubberType: string
  diskType: string
}
export type InspectionDocumentApi = Record<DocumentsTypeEnum, boolean>
export type InspectionEquipmentApi = Record<string, boolean>
export type InspectionDefectApi = {
  detailId: UniqueId
  extentId: UniqueId
  top: number
  left: number
  photos: string[]
}

export type InspectionCreateValues = Omit<InspectionAttributes, 'photos'> & {
  photos: string[]
  equipments: InspectionEquipmentApi
  damages: InspectionDefectApi[]
  repairDamages: string[]
  services: InspectionServiceApi[]
  wheelsStates: InspectionWheelApi[]
  documents: InspectionDocumentApi
}

export class Inspection extends ApiModel<typeof schema, InspectionAttributes> {
  static jsonApiType = 'inspections'

  static schema = schema

  getDuration(): number {
    return this.getAttribute('duration')
  }
  getMileage(): number {
    return this.getAttribute('mileage')
  }

  car(): ToOneRelation<Car, this> {
    return this.hasOne(Car)
  }
  getCar(): Car {
    return this.getRelation('car')
  }

  reason(): ToOneRelation<InspectionReason, this> {
    return this.hasOne(InspectionReason)
  }
  getReason(): InspectionReason {
    return this.getRelation('reason')
  }

  equipments(): ToManyRelation<InspectionEquipment, this> {
    return this.hasMany(InspectionEquipment)
  }
  getEquipments(): InspectionEquipment[] {
    return this.getRelation('equipments')
  }

  documents(): ToManyRelation<InspectionDocument, this> {
    return this.hasMany(InspectionDocument)
  }
  getDocuments(): InspectionDocument[] {
    return this.getRelation('documents')
  }

  wheelsStates(): ToManyRelation<InspectionWheelsState, this> {
    return this.hasMany(InspectionWheelsState)
  }
  getWheelsStates(): InspectionWheelsState[] {
    return this.getRelation('wheelsStates')
  }

  getPhotos(): ThumbType[] {
    return this.getAttribute('photos')
  }

  static async create(values: InspectionCreateValues) {
    const url = `${Inspection.getJsonApiUrl()}/actions/create`

    const client = Inspection.httpClient.getImplementingClient()

    return await client.post(url, values)
  }

  static async savePhoto(
    photo: File,
    carId: UniqueId,
    type: InspectionPhotoTypeEnum,
  ): Promise<ThumbType> {
    const url = `${Inspection.getJsonApiUrl()}/actions/upload-photo`

    const data = new FormData()
    data.append('photo', photo)
    data.append('carId', carId)
    data.append('type', type)

    const client = Inspection.httpClient.getImplementingClient()

    const response = await client.post<ThumbType>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response?.data
  }
}
