import BrushIcon from '@mui/icons-material/Brush'
import DeleteIcon from '@mui/icons-material/Delete'
import { ThumbType } from '~/shared/api'

import { Img } from '~/shared/ui/Img'
import {
  FilesContainer,
  FileWrap,
  ThumbActionButton,
  ThumbActions,
} from './styled'

type UploadFilesProps = {
  previews?: ThumbType[]
  gridTemplateColumns?: string
  onDelete?: (uuid: string) => void
  onChange?: (image: ThumbType) => void
}

export function PreviewsList({
  previews,
  gridTemplateColumns,
  onDelete,
  onChange,
}: UploadFilesProps) {
  if (!previews?.length) return null

  return (
    <FilesContainer gridTemplateColumns={gridTemplateColumns}>
      {previews.map((file) => (
        <FileWrap key={file.uuid}>
          <ThumbActions>
            {onDelete && (
              <ThumbActionButton
                onClick={() => onDelete(file.uuid)}
                aria-label='Удалить'
              >
                <DeleteIcon fontSize='medium' color='warning' />
              </ThumbActionButton>
            )}
            {onChange && (
              <ThumbActionButton
                onClick={() => onChange(file)}
                aria-label='Удалить'
              >
                <BrushIcon fontSize='medium' color='secondary' />
              </ThumbActionButton>
            )}
          </ThumbActions>
          <Img url={file.originalUrlDownload} />
        </FileWrap>
      ))}
    </FilesContainer>
  )
}
