import { Alert, AlertTitle, Button, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import * as React from 'react'

type Props = {
  children: React.ReactNode
}

function ErrorFallback() {
  return (
    <Alert role='alert' severity='error' variant='standard'>
      <AlertTitle>На странице произошла ошибка</AlertTitle>
      <Typography mb={1}>
        Если после обновления страницы ошибка не проходит, обратитесь к
        администратору
      </Typography>
      <Button
        variant='contained'
        size='small'
        color='warning'
        onClick={() => window.location.reload()}
      >
        Обновить
      </Button>
    </Alert>
  )
}

export function ErrorBoundary(props: Props) {
  const { children } = props
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  )
}
