import { useStore } from 'effector-react'
import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { requestCarFx } from '~/features/Inspection/model'
import {
  CarBodyInspectionForm,
  CarBodyInspectionFormValues,
  Defect,
  DefectDrawer,
  DefectRepairModal,
  getEquipmentShortage,
  Header,
  inspectionFormModel,
  NotCompleteSetModal,
  useDefect,
  LegendModal,
} from '~/entities/Inspection'
import { ProgressOverlay } from '~/shared/ui/Loaders'
import { useModal } from '~/shared/ui/Modal'
import { PolygonMarkers } from '~/shared/ui/Polygon'
import carBodySvg from '~/shared/ui/Polygon/Images/carBody.svg'
import { goToNextStep } from '../stepModel'
import { CarPhotos } from './CarPhotos'
import { CAR_BODY_POLYGONS } from './constants'

type CarBodyInspectionStepProps = {
  form: UseFormReturn<CarBodyInspectionFormValues>
  defects: Defect[]
  onDefectsSet: (defects: Defect[]) => void
  canDefectRepair: boolean
}

export function CarBodyInspectionStep({
  form,
  defects,
  onDefectsSet,
  canDefectRepair,
}: CarBodyInspectionStepProps) {
  const { isModalShow, openModal, closeModal } = useModal()

  const isCarDetailsPending = useStore(
    inspectionFormModel.requestCarDetailsFx.pending,
  )
  const isCarDefectsPending = useStore(
    inspectionFormModel.requestDamagesExteriorFx.pending,
  )
  const isCarLoading = useStore(requestCarFx.pending)

  const carDetailsBodyOptions = useStore(
    inspectionFormModel.$carDetailsBodyOptions,
  )
  const lastEquipments = useStore(inspectionFormModel.$lastEquipmentsExterior)

  const [unselectedEquipmentsTitles, setUnselectedEquipmentsTitles] = useState<
    string[]
  >([])

  const {
    handleMarkerSelect,
    handleMarkerAdd,
    handleDefectDrawerClose,
    handleDrawerSuccess,
    isDefectDrawerShow,
    selectedDefect,
    markers,
    isDefectRepairModalShow,
    handleDefectModalClose,
    handleDefectRepair,
    handleDefectRepairCancel,
    newMarker,
    handleMarkerDelete,
  } = useDefect(defects, onDefectsSet)

  const onSuccess = () => {
    const foundUnselectedEquipments = getEquipmentShortage(
      lastEquipments,
      form.getValues('equipmentsExterior'),
    )

    if (foundUnselectedEquipments?.length) {
      setUnselectedEquipmentsTitles(
        foundUnselectedEquipments.map(({ title }) => title),
      )
      openModal()
      return
    }

    goToNextStep()
  }

  const handleModalOk = () => {
    closeModal()
    goToNextStep()
  }

  return (
    <>
      <Header title='Осмотр снаружи' extra={<LegendModal />} />

      <ProgressOverlay
        loading={!isCarLoading && (isCarDetailsPending || isCarDefectsPending)}
      >
        <PolygonMarkers
          polygons={CAR_BODY_POLYGONS}
          onMarkerSelect={handleMarkerSelect}
          onMarkerAdd={handleMarkerAdd}
          markers={markers}
          img={carBodySvg}
          dataTestId='car-body-polygon'
        />
      </ProgressOverlay>

      <CarPhotos />

      <CarBodyInspectionForm onSuccess={onSuccess} form={form} />

      <DefectDrawer
        selectedDefect={selectedDefect}
        isShow={isDefectDrawerShow}
        onClose={handleDefectDrawerClose}
        onSuccess={handleDrawerSuccess}
        carDetailsOptions={carDetailsBodyOptions}
        newMarker={newMarker}
        onDeleteMarker={handleMarkerDelete}
      />

      <DefectRepairModal
        selectedDefect={selectedDefect}
        isShow={isDefectRepairModalShow}
        onCancel={handleDefectModalClose}
        onRepairOk={handleDefectRepair}
        onRepairCancel={handleDefectRepairCancel}
        canRepair={canDefectRepair}
      />

      <NotCompleteSetModal
        isShow={isModalShow}
        onCancel={closeModal}
        onOk={handleModalOk}
        values={unselectedEquipmentsTitles}
      />
    </>
  )
}
