import { Plugins } from '@capacitor/core'
import {
  AppUpdate,
  AppUpdateAvailability,
} from '@capawesome/capacitor-app-update'
import * as Sentry from '@sentry/react'
import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { interval } from 'patronum'
import { isDevEnv, isAppStore, isRuStore } from '~/shared/config/env'

export const Gate = createGate()
const domain = createDomain('reload.app')

const checkAppUpdateFx = domain.createEffect({
  handler: async () => {
    try {
      if (isRuStore) {
        const res = await Plugins.RuStoreUpdate.checkForUpdates()

        return res?.updateAvailable as boolean
      }
      if (isAppStore) {
        const updateInfo = await AppUpdate.getAppUpdateInfo()
        return (
          updateInfo.updateAvailability ===
          AppUpdateAvailability.UPDATE_AVAILABLE
        )
      }
      return false
    } catch (e) {
      Sentry.captureException(e)
      return false
    }
  },
})

export const openStore = domain.createEvent()
export const openStoreFx = domain.createEffect({
  handler: async () => {
    if (isRuStore) {
      await Plugins.RuStoreUpdate.openRuStore()
      return
    }
    if (isAppStore) {
      await AppUpdate.openAppStore()
      return
    }
  },
})

sample({
  clock: openStore,
  target: openStoreFx,
})

export const $needUpdateApp = domain
  .createStore<boolean>(false)
  .on(checkAppUpdateFx.doneData, (_, balance) => balance)
  .on(Gate.close, () => false)

sample({
  clock: Gate.open,
  target: checkAppUpdateFx,
})

const intervalRequestStarted = domain.createEvent()
const intervalRequestStopped = domain.createEvent()

if (!isDevEnv) {
  const { tick } = interval({
    timeout: 60000,
    start: intervalRequestStarted,
    stop: intervalRequestStopped,
    leading: false,
    trailing: false,
  })

  sample({
    clock: tick,
    target: checkAppUpdateFx,
  })
  sample({
    clock: Gate.open,
    target: intervalRequestStarted,
  })
  sample({
    clock: Gate.close,
    target: intervalRequestStopped,
  })
}
