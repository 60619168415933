import { Box, styled } from '@mui/material'

export const WrapperLoading = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Image = styled('img')`
  object-fit: contain;
  width: 100%;
`
