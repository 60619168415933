import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useCameraContext } from '~/widgets/CameraProvider/context'
import { ThumbType } from '~/shared/api'
import { ButtonField, PreviewsList } from '~/shared/ui/form'

type CameraProps = {
  buttonTitle: string
  saveFileFn: (file: File) => void
  name: string
  gridTemplateColumns?: string
  canPaint?: boolean
  errorMessage?: string
  isLoading?: boolean
  showPreviewList?: boolean
  helpers?: string[]
}

export function Camera({
  buttonTitle,
  saveFileFn,
  name,
  gridTemplateColumns,
  canPaint,
  errorMessage,
  isLoading,
  showPreviewList,
  helpers,
}: CameraProps) {
  const { watch, setValue } = useFormContext()
  const imagesForm = watch(name)

  const images: ThumbType[] = useMemo(() => imagesForm || [], [imagesForm])

  const handleImageDelete = (uuid: string) => {
    setValue(
      name,
      images?.filter((image) => image.uuid !== uuid),
    )
  }

  const { cameraOpen } = useCameraContext()

  const helperCameraOpen = () => {
    cameraOpen(saveFileFn, canPaint, helpers)
  }

  return (
    <>
      <ButtonField
        onClick={helperCameraOpen}
        errorMessage={errorMessage}
        isLoading={isLoading}
        isCheck={!!images?.length}
        dataTestId={name}
      >
        {buttonTitle}
      </ButtonField>
      {showPreviewList && (
        <PreviewsList
          previews={images}
          onDelete={handleImageDelete}
          gridTemplateColumns={gridTemplateColumns}
        />
      )}
    </>
  )
}
