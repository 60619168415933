import { LoadingButton } from '@mui/lab'
import { Stack, Typography } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { Modal } from '../Modal'
import { $needUpdateApp, Gate, openStore, openStoreFx } from './model'

export function ReloadApp() {
  useGate(Gate)

  const needUpdateApp = useStore($needUpdateApp)
  const isLoading = useStore(openStoreFx.pending)

  return (
    <Modal isShow={needUpdateApp}>
      <Stack spacing={2} sx={{ marginBottom: '24px' }}>
        <Typography fontSize='24px' lineHeight='26px'>
          Доступна новая версия.
        </Typography>
        <Typography fontSize='16px' lineHeight='18px'>
          Обновите приложение для дальнейшего использования!
        </Typography>

        <LoadingButton
          variant='outlined'
          color='warning'
          fullWidth
          onClick={() => openStore()}
          loading={isLoading}
        >
          Обновить
        </LoadingButton>
      </Stack>
    </Modal>
  )
}
